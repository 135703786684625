<script lang="ts" setup> 

    const props = defineProps({ 
        blok: Object 
    })

    const width = computed(() => {
        return props.blok?.image?.filename.split('/')[5].split('x')[0]
    })

    const height = computed(() => {
        return props.blok?.image?.filename.split('/')[5].split('x')[1]
    })

</script>

<template>
   <NuxtImg
        v-if="blok?.image && blok?.image?.filename"
        provider="storyblok"
        :width="width"
        :height="height" 
        :sizes="`xs:100vw sm:${width} md:${width} narrow:${width} lg:${width} xl:${width} 1xl:${width} normal:${width} 2xl:${width} 3xl:${width} wide:${width}`"
        quality="75"
        fit="in"
        placeholder
        :src="blok?.image?.filename"
        :alt="blok?.image?.alt || blok?.image?.title || '#'" 
        fetchpriority="low"
        decoding="async"
        loading="lazy"
        class="w-full h-auto mx-auto rounded-1"
    />
</template>